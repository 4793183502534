
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React,{ RefObject } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuOpen:boolean;
  authToken:string | null;
  anchorEl:Element | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: RefObject<HTMLDivElement> ;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = React.createRef<HTMLDivElement>();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      menuOpen:false,
      authToken:"",
      anchorEl:null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  

  // Customizable Area Start
  async componentDidMount() {
    this.tokenGetFunct()
    if (this.scrollRef.current) {
      window.scrollTo(0, 0);
    }
  }
  tokenGetFunct=()=>{
    const token = localStorage.getItem('authToken');
    if(token){
      this.setState({authToken:token})
    }
  }
  handleMenuToggle = () => {
   this.setState({menuOpen:!this.state.menuOpen})
};
handleMenuOpen = (event:React.MouseEvent<HTMLElement>) => {
  this.setState({ anchorEl: event.currentTarget });
};

handleMenuClose = () => {
  this.setState({ anchorEl: null });
};
logoutFunct=()=>{
  localStorage.removeItem("authToken")
  localStorage.removeItem("userData")
  this.setState({authToken:""})
  this.props.navigation.navigate("Home");
 
}
loginRedirect=()=>{
  this.props.navigation.navigate("EmailAccountLoginBlock");
}
  handleHome = (itemKey: string) => {
    this.props.navigation.navigate(itemKey);
  }
  handleProfile = ()=>{
    window.location.href = "userProfile"
  }
  // Customizable Area End
}
