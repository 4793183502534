import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import moment from "moment";

export interface CountryType{
  "data": CountryArry[]
}
export interface CountryArry{

  "id": string,
  "type": string,
  "attributes": {
      "name": string,
      "emoji_flag":string,
      "country_code": string
  }
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isVisibleModal:boolean;
  isAddmodel:boolean;
  valueTab:number,
  checkedItems: { [key: string]: boolean };
  inputValues: { [key: string]: {prefix:string; firstName:string; lastName:string;gender:string;emailId:string;age:string;passportNo:string;nationality:string;countryCode: string;image:string; mobileNo: string } };
  inputErrors:  { [key: string]: {prefix:string; firstName:string; lastName:string;gender:string;emailId:string;age:string;passportNo:string;nationality:string;countryCode: string;image:string; mobileNo: string } };
  countBaged:number;
  countsEle:Array<number>
  setFile:string;
  setFileName:string
  countryData:CountryArry[];
  bookingDataAll:any;
  xDateAir:any;
  savedData:any;
  travellerObjData:any;
  checkboxData:any;
  prefixData:any;
  countrycode:string;
  mobile:string;
  email:string;
  errors:any;
  AirSegment:any;
  AirPricingSolution:any;
  xml_res:string;
  errorInfo:string;
  loading:boolean;
  reviewModal:boolean;
  travellerInfoData:any;
  seatSelectionBox:boolean;
  seatAirItenanry:any;
  seatMatrix:any;
  wholeDataMatrix:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class OrderManagementOneWayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  countryCodeApiCallId:string="";
  createBookingApiCallId="";
  createSeatSelectionApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      wholeDataMatrix:[],
      seatAirItenanry:[],
      seatSelectionBox:false,
      travellerInfoData:[],
      reviewModal:false,
      loading:false,
      errorInfo:"",
      countrycode:"",
      mobile:"",
      email:'',
      prefixData:[
        'Mr', 'Ms', 'Master', 'Miss', 'Mrs'
      ],
      errors:{
        countryCode:"",
        mobile:"",
        email:'',
        checkboxAll:''
      },
      checkboxData:[],
      travellerObjData:{},
      savedData:[],
      xDateAir:[],
      bookingDataAll:null,
      countryData:[],
      setFile:"",
      setFileName:"",
      isVisibleModal:false,
      checkedItems: {},
      inputValues: {},
      inputErrors: {},
      isAddmodel:false,
      valueTab:0,
      countBaged:1,
      countsEle: [0,0,0],
      AirSegment:[],
      AirPricingSolution:{},
      xml_res:"",
      seatMatrix: [
        [{ type: 'availablePaid' }, { type: 'selectedByUser' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }],
        [{ type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }],
        [{ type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }],
        [{ type: 'booked' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'clicked' }, { type: 'clicked' }, { type: 'booked' }],
        [{ type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'reservation' }, { type: 'free' }, { type: 'free' }],
        [{ type: 'free' }, { type: 'free' }, { type: 'free' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'paidAvailable' }],
        [{ type: 'paidAvailable' }, { type: 'booked' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'availablePaid' }, { type: 'booked' }],
        [{ type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'free' }, { type: 'free' }],
        [{ type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }, { type: 'booked' }],
        [{ type: 'free' }, { type: 'booked' }, { type: 'free' }, { type: 'occupied' }, { type: 'occupied' }, { type: 'occupied' }],
        [{ type: 'booked' }, { type: 'booked' }, { type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'free' }],
        [{ type: 'free' }, { type: 'free' }, { type: 'free' }, { type: 'booked' }, { type: 'booked' }, { type: 'free' }],
      ] 
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const promoCodeData = message.getData(
        getName(MessageEnum.OrderManagementDataInfo)
      );
      localStorage.setItem('promoCodeData', JSON.stringify(promoCodeData));
     
      }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.countryCodeApiCallId &&  this.countryCodeApiCallId != null:
            this.handleGetAllCountryList(responseJson);
            break;
          case apiRequestCallId === this.createBookingApiCallId &&  this.createBookingApiCallId != null:
            this.handleGetPnrRes(responseJson);
            break;
          case apiRequestCallId===this.createSeatSelectionApiCallId && this.createSeatSelectionApiCallId!=null:
            this.handleGetSeatRes(responseJson);
          break
         
         default:
        }
      }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    
    this.countrylistDataApi();

    setTimeout(() => {
      const travellerObj = this.getTotalTraveller();
          if (travellerObj) {
            this.setState({travellerObjData:travellerObj})
            this.gettravellerData(travellerObj)
          } 
      const promoCodeData = this.getPromoCodeData();
  
      if (promoCodeData) {
          this.getAirItenanryData(promoCodeData.AirItenanryData)
      } 
     
  }, 1000);

}
gettravellerData=(item:any)=>{
  let totalcount = item.adult + item.childrens + item.infant;
  let newTravellers = [];
  let newCheckedItems:any = {};

for (let i = 1; i <= totalcount; i++) {
  newTravellers.push({
    id: i,
    Traveller: `ADD TRAVELLER ${i}`
  });
  newCheckedItems[`checkbox${i}`] = false;
}


                                  
this.setState({checkboxData:newTravellers})
}
getTotalTraveller() {
  const objectData = localStorage.getItem('totalTraveller');
  return objectData ? JSON.parse(objectData) : null;
}
getPromoCodeData() {
  const promoCodeData = localStorage.getItem('promoCodeData');
  return promoCodeData ? JSON.parse(promoCodeData) : null;
}
getAirItenanryData=(AirItenanry:any)=>{
  this.setState({seatAirItenanry:AirItenanry})
}

 formattedData =(item:any)=>{
  return item?.map((item:any) => item.replace(/\n/g, "<br/>"))
.join("<br/><br/>");
}

cityNameFunct=(item:string)=>{
  const airport = this.state.xDateAir.find((airport:any) => airport?.Code === item);
  return airport ? airport.Name : null;
}
diffTime=(ArrivalTime:string, DepartureTime:string)=>{
  const arrivalMoment = moment(ArrivalTime);
  const departureMoment = moment(DepartureTime);

  const duration = moment.duration(arrivalMoment.diff(departureMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();
 return `${hours} hours ${minutes} minutes`;
}
convertFormatDay = (dateString: string | number | Date) => {
 if(dateString!=undefined){
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', weekday: 'long' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  const [weekday, month, day] = formattedDate.split(' ');
  return `${day.slice(0, 2)} ${month.slice(0, 3)}, ${weekday}`;
 }
};
convertFormatTime = (isoString: moment.MomentInput) => {
  const formattedDate = moment(isoString).format('hh:mm A');
  return formattedDate
}
  cancelationFessApply=()=>{
    this.setState({
      isVisibleModal:true,
    })
  }
  resetModal=()=>{
    this.setState({
      isVisibleModal:false,
    })
  }
 
  resetaddModal=()=>{
    this.setState({
      isAddmodel:false,
    })
  }
  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ valueTab: newValue });
  };
  decreaseCount = (index:number) => {
    this.setState((prevState) => {
      const newCounts = [...prevState.countsEle];
      if (newCounts[index] > 1) {
        newCounts[index] -= 1;
      } else {
        newCounts[index] = 0; 
      }
      return { countsEle: newCounts };
    });
  };

  increaseCount = (index:number) => {
    this.setState((prevState) => {
      const newCounts = [...prevState.countsEle];
      if (newCounts[index] === 0) {
        newCounts[index] = 1; 
      } else {
        newCounts[index] += 1;
      }
      return { countsEle: newCounts };
    });
  };
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [name]: checked,
      },
     
    }));    
  };
  handleFileChange = (event: ChangeEvent<HTMLInputElement>, idFile: number) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        inputValues: {
          ...prevState.inputValues,
          [idFile]: { ...prevState.inputValues[idFile], image: file.name },
        },
        inputErrors: {
          ...prevState.inputErrors,
          [idFile]: { ...prevState.inputErrors[idFile], image: false },
        },
        setFileName: file.name,
      }));
    }
  };

  handleBrowseClick = (idElemt: number): void => {
    const element = document.getElementById(`upload-file-${idElemt}`) as HTMLInputElement | null;
  if (element) {
    element.click();
  }
  };
  handleSaveClick = (idSave: number) => {
    const { inputValues } = this.state;
    const {
      prefix='',
      firstName = '',
      lastName = '',
      gender ='',
      countryCode = '',
      mobileNo = '',
      image = '',
      emailId = '',
      age='',
      passportNo = '',
      nationality = ''
    } = inputValues[idSave] || {};

    const isEmpty = (value: string) => !value || value.trim() === '';

    const inputErrors = {
      prefix:isEmpty(prefix),
      firstName: isEmpty(firstName),
      lastName: isEmpty(lastName),
      gender:isEmpty(gender),
      countryCode: isEmpty(countryCode),
      mobileNo: isEmpty(mobileNo) || !this.validateMobile(mobileNo),
      emailId: isEmpty(emailId) || !this.validateEmail(emailId),
      age:isEmpty(age) || !this.validateAge(age),
      nationality: isEmpty(nationality),
    };

    if (Object.values(inputErrors).some((error) => error)) {
      this.setState((prevState) => ({
        inputErrors: {
          ...prevState.inputErrors,
          [idSave]: inputErrors,
        },
      }));
    } else {
      this.setState((prevState) => ({
        inputErrors: {
          ...prevState.inputErrors,
          [idSave]: { prefix:false,firstName: false, mobileNo: false, lastName: false, countryCode: false, image: false, emailId: false,age:false, passportNo: false, nationality: false },
        },
        savedData: {
          ...prevState.savedData,
          [idSave]: {
            prefix,
            firstName,
            lastName,
            gender,
            countryCode,
            mobileNo,
            image,
            emailId,
            age,
            passportNo,
            nationality,
          },
        },
      }), () => {
        const updatedCheckboxData = this.state.checkboxData.map((traveller:any, index:number) => ({
          ...traveller,
          firstName: this.state.savedData[traveller.id]?.firstName || '',
          email: this.state.savedData[traveller.id]?.emailId || '',
          mobile: this.state.savedData[traveller.id]?.mobileNo || ''
        }));
      
        this.setState({ checkboxData: updatedCheckboxData });
        this.setState(prevState => ({
          checkedItems: {
              ...prevState.checkedItems,
              [`checkbox${idSave}`]: false
          }
      }));
      });
      

    }
  };
 handleInputChange = (event: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, idIput: number, inputType: string) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      inputValues: {
        ...prevState.inputValues,
        [idIput]: { ...prevState.inputValues[idIput], [inputType]: value },
      },
      inputErrors: {
        ...prevState.inputErrors,
        [idIput]: { ...prevState.inputErrors[idIput], [inputType]: value.trim() === '' },
      },
    }));
  };
  handleGetAllCountryList=(responseJson:CountryType)=>{
    if(responseJson){
      this.setState({countryData:responseJson.data})
    }
      }
      countrylistDataApi = () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.countryCodeApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.apiEndPointGetCountry
        );
    
        const header = {
          "Content-Type": configJSON.apiContentType,
        };
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.listOfOrdersMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      setCountryCode = (text: string) => {
        this.setState({
          countrycode: text,
          errors: { ...this.state.errors, countrycode: '' },
        });
      };
      setMobile= (text: string) => {
        this.setState({
          mobile: text,
          errors: { ...this.state.errors, mobile: '' },
        });
      };
      setEmail= (text: string) => {
        this.setState({
          email: text,
          errors: { ...this.state.errors, email: '' },
        });
      };
      validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
          return false
        }
      
        return true; 
      };
      validateMobile = (mobile: string) => {
        const numericPattern = /^[0-9]+$/;
    return mobile && numericPattern.test(mobile);
      };

      validateAge = (agekey: string) => {
        const numericPattern = /^[0-9]+$/;
    return agekey && numericPattern.test(agekey);
      };
      
      validateCountryCode = (countrycode: string) => {
        return countrycode ? "" : "Country code is required";
      };
      validateEmailSec=(email:string)=>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    
        return emailRegex.test(email) ? "" : "Invalid email format";
      }
      validateMobileSec = (mobile: string) => {
        const numericPattern = /^[0-9]+$/;
    return numericPattern.test(mobile)?"":"Invalid mobile number";
      };
      handleSubmitOrderMgmt=()=>{
        const { email, countrycode, mobile } = this.state;
        const errors: any = {
                                             
          email: this.validateEmailSec(email),
          countrycode: this.validateCountryCode(countrycode),
          mobile: this.validateMobileSec(mobile),
          checkboxAll: this.validateAllCheckboxes(),
        };
        const isValid = Object.values(errors).every((error) => !error);
    
        if (isValid) {
           this.confirmReview()
        } else {
          this.setState({ errors });
        }
      }
      resetReviewModal=()=>{
        this.setState({reviewModal:false})
      }
      confirmSeatSelection=()=>{
        this.setState({reviewModal:false, seatSelectionBox:true})
        this.seatSelectionCallFunction()
      }
      viewDetailFunct=()=>{
        this.setState({ seatSelectionBox:false})
      }
      confirmReview=()=>{
        let travellerInfos =Object.values(this.state.savedData).map((item:any,index:number)=>{
          return {
           
            "gender":item.gender,
            "prefix": item.prefix,
            "first_name": item.firstName,
            "last_name": item.lastName,
            "email": item.emailId,
            "traveler_id": index+1,
            "nationality":item.nationality,
            "passport_number":item.passportNo,
            "traveller_type": this.genDerFunction(item.gender),
            "country_code":item.countryCode,
            "number": item.mobileNo,
           
            "age":parseInt( item.age)
          }
        })
        this.setState({travellerInfoData:travellerInfos,reviewModal:true})
      }
      genDerFunction=(age:string)=>{
        if (age >= "0" && age <= "1") {
          return 'INF'; 
      } else if (age >= "2" && age <= "12") {
          return 'CNN'; 
      } else {
          return 'ADT'; 
      }
      }
      handleSubmitContinue=()=>{
        this.setState({loading:true})
        let travellerInfo =Object.values(this.state.savedData).map((item:any,index:number)=>{
          return {
            "prefix": item.prefix,
            "first_name": item.firstName,
            "last_name": item.lastName,
            "email": item.emailId,
            "gender":item.gender,
            "traveller_type": this.genDerFunction(item.gender),
            "country_code":item.countryCode,
            "number": item.mobileNo,
            "nationality":item.nationality,
            "passport_number":item.passportNo,
            "traveler_id": index+1,
            "age":parseInt( item.age)
          }
        })
        let booking_sent_detail = {
         "countrycode":this.state.countrycode,
          "phone_number":this.state.mobile,
          "email":this.state.email,
        }
        const header = {
          "Content-Type": configJSON.apiContentType,
          "Token": localStorage.getItem("authToken"),
        };
        const httpBody = {
          "traveler_info": travellerInfo,
          "booking_sent_detail":booking_sent_detail,
          "AirSegment":this.state.AirSegment,
          "AirPricingSolution":this.state.AirPricingSolution,
          "xml_res":this.state.xml_res
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createBookingApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookingAPiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createOrderMethod
        );


                                     
        runEngine.sendMessage(requestMessage.id, requestMessage); 
      }

      validateAllCheckboxes = () => {
        const areAllChecked = this.state.checkboxData.every((traveller:any) => traveller.firstName!="" && traveller.firstName!=undefined);
        return areAllChecked ? "" : "All travellers must be selected.";
      };
      handleGetPnrRes=(responseJson:any)=>{
        if(!responseJson.data.ErrorInfo){
          this.setState({errorInfo:"", loading:false})
          this.props.navigation.navigate("seatselection")
        }else{
          this.setState({errorInfo:responseJson.data.ErrorInfo.Description})
        }
      }
    

      seatSelectionCallFunction=()=>{
        const header = {
          "Content-Type": configJSON.apiContentType,
          "Token": localStorage.getItem("authToken"),
        };
        const httpBody = {
          "AirItenanry": this.state.seatAirItenanry,
          "adult": this.state.travellerObjData.adult, 
          "children": this.state.travellerObjData.children, 
          "infant":  this.state.travellerObjData.infant
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createSeatSelectionApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.seatApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createOrderMethod
        );                            
        runEngine.sendMessage(requestMessage.id, requestMessage); 
      }
  handleGetSeatRes = (responseJson: any) => {
    if(responseJson){
      let mat = responseJson.data.Rows.map((item: any) => {
        let dataMatrix = this.convertRowsToMatrix(item.Row);
        let airSegment = responseJson.data.AirSegment.find((segment:any) => segment.Key === item.SegmentRef);
        return {
          ...item,
          "AirSegment":airSegment,
          "SegmentRef": item.SegmentRef,
          Row: dataMatrix,
          "Traveldata":this.state.travellerInfoData
        };
      });
  this.setState({ wholeDataMatrix:mat})
    }

  }

   getMaxRowAndSeat=(rows: any[])=> {
    let maxRowNumber = 0;
    let maxSeatLetter = '';

    rows.forEach((rowObj) => {
        const rowNumber = parseInt(rowObj.Number, 10);
        if (rowNumber > maxRowNumber) {
            maxRowNumber = rowNumber;
        }
        rowObj.Facility.forEach((facility: any) => {
            if (facility.Type === "Seat" && facility.SeatCode) {
                const seatLetter = facility.SeatCode.split('-')[1];
                if (seatLetter > maxSeatLetter) {
                    maxSeatLetter = seatLetter;
                }
            }
        });
    });

    return { maxRowNumber, maxSeatLetter };
}

 createEmptyMatrix=(maxRowNumber: number, maxSeatIndex: number)=>{
    const matrix = [];

    for (let i = 1; i <= maxRowNumber; i++) {
        matrix[i] = Array(maxSeatIndex + 1).fill({
            availability: 'booked',
            seatCode: '',
            windowSeat: false,
            side: ''
        });
    }

    return matrix;
}

 createRowArray=(maxSeatIndex: number)=>{
    return Array(maxSeatIndex + 1).fill({
        availability: 'booked',
        seatCode: '',
        windowSeat: false,
        side: ''
    });
}

 processFacility=(facility: any, rowArray: any[])=> {
    if (facility.Type === "Seat") {
        const seatCode = facility.SeatCode;
        const seatLetter = seatCode.split('-')[1];
        const seatIndex = seatLetter.charCodeAt(0) - 'A'.charCodeAt(0);

        const { windowSeat, side } = this.getSeatCharacteristics(facility.Characteristic);

        rowArray[seatIndex] = {
            availability: facility.Availability.toLowerCase(),
            seatCode: seatCode,
            windowSeat: windowSeat,
            side: side
        };
    } else if (facility.Type === "Aisle") {
        const aisleIndex = 3;
        rowArray[aisleIndex] = {
            availability: 'booked',
            seatCode: '',
            windowSeat: false,
            side: ''
        };
    }
}

 getSeatCharacteristics=(characteristics: any[]): { windowSeat: boolean, side: string }=> {
    let windowSeat = false;
    let side = "";

    characteristics.forEach((characteristic) => {
        if (characteristic.PADISCode === "W") {
            windowSeat = true;
        }
        if (characteristic.PADISCode === "LS") {
            side = "left";
        } else if (characteristic.PADISCode === "RS") {
            side = "right";
        }
    });

    return { windowSeat, side };
}

 convertRowsToMatrix=(rows: any)=> {
    const { maxRowNumber, maxSeatLetter } = this.getMaxRowAndSeat(rows);
    const maxSeatIndex = maxSeatLetter.charCodeAt(0) - 'A'.charCodeAt(0);
    const matrix = this.createEmptyMatrix(maxRowNumber, maxSeatIndex);

    rows.forEach((rowObj: any) => {
        const rowNumber = parseInt(rowObj.Number, 10);
        const rowArray = this.createRowArray(maxSeatIndex);

        rowObj.Facility.forEach((facility: any) => {
           this.processFacility(facility, rowArray);
        });

        matrix[rowNumber] = rowArray;
    });

    return matrix;
}

availableFlight = (rowIndex: any, seatIndex: any, index: any, Traveldata: any) => {
  const travelDataLength = Traveldata.length;

  const selectedSeatsCount = this.state.wholeDataMatrix[index].Row.flat().filter((seat: any) => seat.availability === 'selectedByUser').length;
  if (selectedSeatsCount < travelDataLength || this.state.wholeDataMatrix[index].Row[rowIndex][seatIndex].availability === 'selectedByUser') {
    this.setState((prevState: any) => {
      const newWholeDataMatrix = prevState.wholeDataMatrix.map((flightItems: any, flightIndex: any) => {
        if (flightIndex === index) {
          return {
            ...flightItems,
            Row: this.dataSelectedSeat(flightItems.Row, rowIndex, seatIndex)
          };
        }
        return flightItems;
      });

      return {
        wholeDataMatrix: newWholeDataMatrix
      };
    });
  }
};

dataSelectedSeat=(flightItems:any,rowIndex:any, seatIndex:any)=>{
  return flightItems.map((row: any, rIndex: any) => {
    if (rIndex === rowIndex) {
      return row.map((col: any, cIndex: any) => {
        if (cIndex === seatIndex) {
          const newAvailability = col.availability === 'available' ? 'selectedByUser' : 'available';
          return {
            ...col,
            availability: newAvailability
          };
        }
        return col;
      });
    }
    return row;
  })
}


  // Customizable Area End
}
