import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import currentChange from "moment";
const navigation = require("react-navigation");
export interface ErrorMessage {
  token: string;
}

interface City {
  from: string;
  to: string;
  departureDate: string;
}

interface DateRange {
  selection: {
    startDate: Date;
    endDate: Date;
  };
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filtersDrawer: boolean;
  priceListName: Array<string>;
  anchorElTripType: HTMLElement | null;
  fromAnchorEl: HTMLElement | null;
  toAnchorEl: HTMLElement | null;
  anchorElClass: HTMLElement | null;
  tripOpen: boolean;
  anchorEl: HTMLElement | null;
  selectedTripType: string;
  anchorElTo: null;
  cities: City[];
  showTrip: boolean;
  isOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
  endDate1: Date | null;
  countAdult: number;
  TravelStatus: string;
  countChild: number;
  countInfant: number;
  error: string;
  activePicker: string;
  currentIndex: number;
  flightDetailsDialog: boolean;
  flightDesignation: Array<string>;
  ShowFlightDetails: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientViewProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editFullNameAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      filtersDrawer: false,
      priceListName: [
        "FARES",
        "CABIN BAG",
        "CHECK-IN",
        "CANCELLATION",
        "DATE CHANGE",
        "SEAT",
        "MEAL",
      ],
      anchorElTripType: null,
      tripOpen: false,
      anchorEl: null,
      selectedTripType: "Multicity",
      anchorElTo: null,
      cities: [{ from: "", to: "", departureDate: "" }],
      showTrip: false,
      isOpen: false,
      startDate: null,
      endDate: null,
      endDate1: null,
      countAdult: 1,
      TravelStatus: "ECONOMY",
      countChild: 0,
      countInfant: 0,
      fromAnchorEl: null,
      toAnchorEl: null,
      anchorElClass: null,
      error: "",
      activePicker: "",
      currentIndex: 0,
      flightDetailsDialog: false,
      flightDesignation: ["Saver","Comfort","Comfort Plus","Flex"],
      ShowFlightDetails: false
    };

    // Customizable Area End
  }

  // Customizable Area Start
  handleFiltersToggle = () => {
    this.setState({ filtersDrawer: !this.state.filtersDrawer });
  };

  closeTripTypeMenu = () => {
    this.setState({ anchorElTripType: null });
  };

  openFromSearchMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ fromAnchorEl: event?.currentTarget });
  };

  closeFromSearchMenu = () => {
    this.setState({ fromAnchorEl: null });
  };

  openToSearchMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ toAnchorEl: event?.currentTarget });
  };

  closeToSearchMenu = () => {
    this.setState({ toAnchorEl: null });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElTripType: event?.currentTarget });
  };

  handleSelectTriptype = (selecte: string) => {
    this.setState({ selectedTripType: selecte });
  };

  selectDate = (date: Date) => {
    if (date) {
      const dayaa = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear().toString().slice(-2);
      const weekday = date.toLocaleString("default", { weekday: "long" });
      return `${dayaa} ${month}${year} ${weekday}`;
    }
  };

  handleAddCity = () => {
    this.setState((prevState) => ({
      cities: [...prevState.cities, { from: "", to: "", departureDate: "" }],
    }));
  };

  handleInputChange = (
    indexNumber: number,
    field: keyof City,
    value: string
  ) => {
    const cities = [...this.state.cities];
    cities[indexNumber][field] = value;
    this.setState({ cities });
  };

  handleRemoveCity = (indexNumber: number) => {
    this.setState((prevState) => {
      const cities = [...prevState.cities];
      cities.splice(indexNumber, 1);
      return { cities };
    });
  };

  openTripOption = () => {
    this.setState({ showTrip: !this.state.showTrip });
  };

  openClassCategory = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElClass: event?.currentTarget });
  };

  closeClassCategory = () => {
    this.setState({ anchorElClass: null });
  };

  togglePickerM = (picker: string, index: number) => {
    this.setState({ isOpen: true, activePicker: picker, currentIndex: index });
  };

  handleCatuagortClass = (buttonName: string) => {
    this.setState({ TravelStatus: buttonName });
  };

  countDecrease = () => {
    this.setState((prevState) => ({
      countAdult:
        prevState.countAdult === 1
          ? prevState.countAdult
          : prevState.countAdult - 1,
      error: "",
    }));
  };

  errorFunct = (errorMsg: string) => {
    this.setState({ error: errorMsg });
  };

  countIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countAdult: prevState.countAdult + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  countChildDecrease = () => {
    this.setState((prevState) => ({
      countChild:
        prevState.countChild === 0
          ? prevState.countChild
          : prevState.countChild - 1,
      error: "",
    }));
  };

  countChildIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countChild: prevState.countChild + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  countChildInfantDecrease = () => {
    this.setState((prevState) => ({
      countInfant:
        prevState.countInfant === 0
          ? prevState.countInfant
          : prevState.countInfant - 1,
      error: "",
    }));
  };

  countChildInfantIncrease = () => {
    this.setState((prevState) => {
      const total =
        prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countInfant: prevState.countInfant + 1,
          error: "",
        };
      } else {
        this.errorFunct(
          "If you want to add more than 9 travellers please contact email www.doolwa.com"
        );
        return null;
      }
    });
  };

  DateChange = (time: DateRange) => {
    if (this.state.activePicker === "startDate") {
      let determinedDate = time?.selection.startDate;
      if (
        (!this.state.startDate && !this.state.endDate) ||
        (this.state.startDate == time.selection.startDate &&
          this.state.endDate == time.selection.startDate)
      ) {
        determinedDate = time?.selection.endDate;
      }
      this.setState({
        startDate: determinedDate,
        endDate: determinedDate,
        endDate1: null,
      });
    } else {
      this.setState({
        startDate: time.selection.startDate,
        endDate: time.selection.endDate,
        endDate1: time.selection.endDate,
      });
    }
  };

  handleCancelButton = () => {
    this.setState({
      startDate: null,
      activePicker: "",
      endDate: null,
      endDate1: null,
      isOpen: false,
    });
  };

  handleApplyButton = () => {
    this.setState({ isOpen: false, activePicker: "" });
  };

  selectTime = () => {
    return this.state.startDate
      ? currentChange(this.state.startDate).format("DD MMM, ddd YYYY")
      : "Select the departure date";
  };

  handleCancelTravelButton = () => {
    this.setState({
      TravelStatus: "ECONOMY",
      countAdult: 1,
      countChild: 0,
      anchorElClass: null,
      error: "",
    });
  };

  handleDoneButton = () => {
    this.setState({
      anchorElClass: null,
    });
  };

  totalTravellers = () => {
    return `${Number(this.state.countAdult)} Adults ${this.state.TravelStatus}`;
  };

  flightDialogClose = () => {
   this.setState({flightDetailsDialog: false});
  };

  flightDialogOpen = () => {
    this.setState({flightDetailsDialog: true});
   };

   handleFlightDetails = () => {
    this.setState({ShowFlightDetails: !this.state.ShowFlightDetails});
   };
  // Customizable Area End
}
