import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTab: string
  displayCancelledIndex: number | null
  cancelledTripArr: string[]
  refundSteps: {label:string,status:boolean,date:string}[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyTripsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        selectedTab:"Upcoming",
        displayCancelledIndex: null,
        cancelledTripArr:['1','2','3'],
        refundSteps:[]
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msgConst: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgConst);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({ selectedTab: newValue });
    };
    handleViewCancelledTrip = (index: number) => {
        this.setState((prevState) => ({
            displayCancelledIndex: prevState.displayCancelledIndex === index ? null : index
            ,refundSteps: [{label:'Booking Cancelled',status:true,date:"9 July 24, 12:20 PM  "},{label:'Refund processed : $ 3,109 ',status:true,date:"9 July 24, 12:20 PM  "},{label:'Refund credited in account',status:true,date:"9 July 24, 12:20 PM  "}]
        }));
    }
  // Customizable Area End
}
