import React from "react";
import { Box, Button, Grid, Menu, TextField, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

const MultiTravel = (props:any) =>  {
        const {state,
            cityIndex,
            handleClickClass,handleCloseClass,
            decreaseCount,
            increaseCount,
            decreaseCountChild,
            increaseCountChild,
            errorIcon,
            handleClickStatus,
            handleCancelTravel,
            handleDone,
            addAnotherCity,
            iconCloseRemoveItem,
            decreaseCountChildInfant,
            increaseCountChildInfant
        } = props
        return (

          <>
          {cityIndex === 0?
          <>
            <>
       <TextField
                             name="travellers & class"
                            
                             placeholder={"Select no.of travellers\n& Class."}
                             variant="outlined"
                             fullWidth
                             label={(
                              <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                                <span>Travellers & Class</span>
                                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                                  {state.fullTravelObj.countAdult && 
                                              state.fullTravelObj.TravelStatus ? (
                                        <div style={{position: "absolute",width:"134%",
                                          background: "#fff",left:"0px",top:"21px",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:700, fontSize: "32px" }} data-test-id="formatDay">{state.fullTravelObj.countAdult+state.fullTravelObj.countChild} </span>
                                          <span className="textcalendermonth" style={{fontWeight:500, fontSize: "24px" }} data-test-id="formatMonth">{state.fullTravelObj.countAdult+state.fullTravelObj.countChild === 1? "Traveller":"Travellers"}</span><br />
                                          <span className="textcalendername" style={{fontWeight:500, fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)" }} data-test-id="formatYear">{state.fullTravelObj.TravelStatus}</span>
                                        </div>
                                      ) : ''}
                              </div>
                              
                          )}
                             value={""}
                             onClick={handleClickClass}
                             style={{ width: "100%"}}
                             data-test-id="handleClickClass"
                             
                             InputProps={{
                               className:"borderCustom",
                               style: {
                                 borderRadius: "0 10px 10px 0",
                                 
                                 paddingBottom:"10px",
                                 textAlign: "center",
                                 fontSize: "16px",
                                
                               }
                             }}
                             InputLabelProps={{
                               className:"fontLabel",  shrink: true,
                               style: { transform: "translate(14px, 7px)", color:"#000", fontWeight:700,fontSize: "16px" }
                           }}
                           />
                           <Menu
                            PaperProps={{
                              style: {
                                width: 600,
                                borderRadius:"10px"
                              }
                            }}
                            marginThreshold={72}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            data-test-id="handleCloseClass"
                            
                           
                        open={Boolean(state.anchorElClass)}
                        onClose={handleCloseClass}
             className="menuFrom"
                            id="simple-menu"
                            anchorEl={state.anchorElClass}
                             keepMounted
            
           >
            
 <Box style={{padding:"10px"}}>
        <Box className="classTravelHead">
        <Typography style={{fontSize:"12px", color:"#000",fontWeight:700}} variant="body1">
        {state.countAdult} {state.countAdult<=1?"Adult":"Adults"} , {state.countChild} {state.countChild<=0?"Child":"Children"}, {state.countInfant} Infant</Typography>
        <Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",}} variant="body1">
          {state.TravelStatus}</Typography>
        </Box>
   <Box style={{width:"100%",borderBottom:"1px solid rgba(226, 232, 240, 1)",
    marginTop:"10px"}}></Box>
       <Box style={{width:"100%"}}>
         <Grid container spacing={2}>
             <Grid item xs={6} sm={4}>
             <Box sx={{padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}, textAlign:"center"}}>

             <Typography style={{fontSize:"24px", color:"#000",fontWeight:700}} variant="body1">
             {state.countAdult} {state.countAdult<=1?"Adult":"Adults"}</Typography>
          <Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">
            (Aged 18+years)</Typography>
         <Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
         <button data-test-id="decreaseCount"  className="borderNoneCount" onClick={decreaseCount}><RemoveIcon/></button>
           <div className="countShowText">{state.countAdult}</div>
           <button data-test-id="increaseCount" className="borderNoneCount"  onClick={increaseCount}><AddIcon /></button>
         </Box>
         </Box>
             </Grid>
             <Grid item xs={6} sm={4}>
             <Box sx={{ textAlign:"center",padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}}}>


             <Typography style={{fontSize:"24px", color:"#000",fontWeight:700}} variant="body1">
             {state.countChild} {state.countChild<=0?"Child":"Children"}</Typography>
   <Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">
    (Aged 2-12 years)</Typography>


   <Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
              <button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={decreaseCountChild}>
                <RemoveIcon/></button>
              <div className="countShowText">
                {state.countChild}</div>
              <button className="borderNoneCount" data-test-id="increaseCountChild" onClick={increaseCountChild}>
                <AddIcon /></button>
          


         </Box>
         </Box>
             </Grid>
             <Grid item xs={12} sm={4}>
<Box sx={{ textAlign:"center",padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}}}>
<Typography style={{fontSize:"24px", color:"#000",fontWeight:700}} variant="body1">  {state.countInfant} Infant</Typography>
<Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">(Under 2 years)  </Typography>
<Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
<button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={decreaseCountChildInfant}><RemoveIcon/></button>
<div className="countShowText">{state.countInfant}</div>
<button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={increaseCountChildInfant}><AddIcon /></button>

</Box>
</Box>
</Grid>
            
             {
                 state.error &&
                 <Grid item sm={12} >

                         <Box sx={webStyles.errorMsgforCount} className="ErrorMsg">
                         <span className="imgError" style={{width:"5%", marginRight:"5px"}}>
                          <img src={errorIcon} /></span> <span style={{width:"95%"}}>
                         If you want to add more than 9 travellers please contact email <span style={{textDecoration:"underline"}}>info@doolwa.com</span>
                        
                        
                         </span>
                         </Box>

                         </Grid>
                }
             
             <Box sx={{width:"100%",textAlign:"center", padding:"0px 0 0px 0",
             }}>

             <Typography style={{fontSize:"24px", color:"#000",fontWeight:700}} variant="body1">
               Travel Class</Typography>
             </Box>
             <Grid item sm={12}>

             <Box style={{padding:"12px"}}>
    <Grid container spacing={1}>
      <Grid item xs={6} sm={6} md={3} lg={3}>
          <button
            className={`buttonEconomy ${state.TravelStatus === 'ECONOMY' ? 'active' : ''}`}
            data-test-id="handleClickStatus"
            onClick={() => handleClickStatus('ECONOMY')}
          >
            ECONOMY
          </button>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}>
       <button
        className={`buttonEconomy ${state.TravelStatus === 'PREMIUM' ? 'active' : ''}`}
        data-test-id="handleClickStatus2"
        onClick={() => handleClickStatus('PREMIUM')}
      >
        PREMIUM
      </button>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}>
      <button
        className={`buttonEconomy ${state.TravelStatus === 'Business' ? 'active' : ''}`}
        data-test-id="handleClickStatus3"
        onClick={() => handleClickStatus('Business')}
      >
        Business
      </button>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}>
      <button
      className={`buttonEconomy ${state.TravelStatus === 'First Class' ? 'active' : ''}`}
      data-test-id="handleClickStatus4"
      onClick={() => handleClickStatus('First Class')}
    >
      First Class
    </button>
      </Grid>
    </Grid>
 



  </Box>
             </Grid>
             
             <Box  className="buttonBoxapply" style={{padding:"10px", margin:"0px 10px"}}>

                 <Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:700}} data-test-id="handleCancelClassTravel" onClick={handleCancelTravel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                
                 <Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:700}} data-test-id="handleDone" onClick={handleDone}>&nbsp;Done&nbsp;</Button>
               </Box>
              </Grid>
            </Box>
      </Box>
                  </Menu>
      </>
          </>:
           (
            cityIndex === state.multicityTrips.length - 1?

            (
                <Box  style={ {border: "rgba(0, 0, 0, 0.23) 1px solid",
  borderRadius: "0 10px 10px 0", height:"89%",
  paddingBottom:"10px", textAlign: "center",position:"relative",
  fontSize: "16px", display:"flex", justifyContent:"center",alignItems:"center"
}} className="borderCustom">
   <Button
                          onClick={addAnotherCity}
                          data-test-id={`addAnotherCity`}
                          variant="outlined"
                          style={{ marginTop: "10px", fontSize:"16px", fontWeight:700, color:"rgba(23, 100, 160, 1)", border:"1px solid rgba(23, 100, 160, 1)", width:"70%", padding:"10px" }}
                        >
                          + Add Another City
                        </Button>
                        {
                            cityIndex>1 && <div data-test-id={`iconCloseCale`+cityIndex} className="iconCloseCale" onClick={()=>iconCloseRemoveItem(cityIndex)}>
                            <CloseIcon />
                          </div>
                           }
</Box>
                                       
                                      
              ):<><Box  style={ {border: "rgba(0, 0, 0, 0.23) 1px solid",
                borderRadius: "0 10px 10px 0", height:"89%",
                textAlign: "center",
                fontSize: "16px",
                paddingBottom:"10px"
              }} className="borderCustom heigintdd"></Box></>)}
          
          </>
            )
}
const webStyles = {
   
    errorMsgforCount:{
        margin:"0px 10px",
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        fontFamily:"Inter, sans-serif",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        padding: "20px 15px",
        alignItems: "center"
    },
}
export default MultiTravel;

