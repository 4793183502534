export const defaultImg = require("../assets/Day.png")
export const defaultImg1 = require("../assets/Day1.png")
export const groupfirst = require("../assets/group_ad_card_1.png")
export const groupsecond = require("../assets/group_ad_card_2.png")
export const blog1 = require("../assets/blog1.png");
export const blog2 = require("../assets/blog2.png");
export const icon1 = require("../assets/group_2.2.png");
export const icon2 = require("../assets/group_3.2.png");
export const imgNature = require("../assets/image_nature.png")
export const oceanbackground = require("../assets/background.png");
export const logo = require("../assets/logo.png");
export const airoplane = require("../assets/image_airplanemode_active_24px.png");
export const umbrella = require("../assets/image_Beach Umbrella.png");
export const logoFooter = require("../assets/image_logo.png");
export const errorIcon = require("../assets/error.png")
export const noOffers = require("../assets/image_Avoid Flights.png")
export const noDestination = require("../assets/noDestination.png")
export const noBlog = require("../assets/noBlog.png")
export const noBenifit = require("../assets/noTraveling.png")
export const whereToGo = require("../assets/whereToGo.png")
