import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Button,
  Drawer,
  Divider,
  Grid,
  Menu,
  MenuList,
  MenuItem,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
} from "@material-ui/core";
import { Tune } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SearchIcon from "@material-ui/icons/Search";
import Calender from "../../../components/src/Calender";
import MultiCitySearchingController, {
  Props,
} from "./MultiCitySearchingController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import Filteritems from "../../filteritems/src/Filteritems.web";
import {
  flightImg,
  colorOpenIcon,
  cancleCity,
  redErrorIcon,
  cancelIcon,
  greenTrue,
  yellowCheck
} from "./assets";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import "../assets/style.css";
const configJSON = require("./config");
// Customizable Area End

export default class ClientViewProfile extends MultiCitySearchingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  horizontalLine = () => {
    return <Divider className="horizentalLine" />;
  };

  renderFlightSchedule = () => {
    return (
      <Box className="flightSchuduleContainer">
        <Box className="tripContainer">
          <Typography className="tripName">Trip 1</Typography>
        <Box>
          <Typography className="travelTime">
            New Delhi to Mumbai | Mon, 22 Apr
          </Typography>
          <Box className="flightNameBox marginTop">
            <img src={flightImg} className="flightIcon" />
            <Typography className="flightNameText marginLeft">
              Indigo
            </Typography>
            <Typography className="flightNumber">{configJSON.flightNumber}- DTK253</Typography>
          </Box>
        </Box>
        </Box>
        <Box className="flightScheduleTimeBox">
          <Box>
            <Typography className="flightNameText">12:20 AM</Typography>
            <Typography className="startCity marginTop">New Delhi</Typography>
          </Box>
          <Box className="flightTime">
            <Typography className="durationTime blackColor">
              1 h 30 min
            </Typography>
            <Divider className="horizental" />
            <Typography className="durationTime blueColor">
              1 stop via Hyderabad
            </Typography>
          </Box>
          <Box>
            <Typography className="flightNameText">01:30 PM</Typography>
            <Typography className="startCity marginTop">Mumbai</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  renderCard = () => {
    return (
      <Box className="cardContentContainer">
        {this.state.flightDesignation.map((designation: string) => {
          return (
            <Grid item>
              <Card elevation={5} className="cardContainer">
                <CardContent>
                  <Box className="cardContainerBox">
                    <Box className="pricePersionContainer">
                      <Typography className="categoryPrice">$5100</Typography>
                      <Typography className="persionType">per adult</Typography>
                    </Box>
                    <Typography className="categortType" >{designation}</Typography>
                  </Box>
                  <Divider className="cardHorizentalLine" />
                  <Box className="baggageContainer">
                    <Typography className="cardTitleBold">{configJSON.baggage}</Typography>
                    <Box className="quantityContainer">
                      <img src={greenTrue} />
                      <Typography className="baggageQuantity">7 kgs Cabin Baggage</Typography>
                    </Box>
                    <Box className="quantityContainer">
                      <img src={greenTrue} />
                      <Typography className="baggageQuantity">15 kgs Check-in  Baggage</Typography>
                    </Box>
                  </Box>
                  <Box className="baggageContainer">
                    <Typography className="cardTitleBold">{configJSON.baggage}</Typography>
                    <Box className="quantityContainer">
                      <img src={yellowCheck} />
                      <Typography className="baggageQuantity">Cancellation fee starts at $3200 (upto 3 hrs
                        before departure).</Typography>
                    </Box>
                    <Box className="quantityContainer">
                      <img src={yellowCheck} />
                      <Typography className="baggageQuantity">Date change fee starts at $3500 (upto 3 hrs
                        before departure).
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="BookNowContainer">
                    <Button className="bookButton">
                      {configJSON.bookNow}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Box>
    );
  };

  renderFlightDetailsModal = () => {
    return (
      <DialogFlightContainer
        PaperProps={{
          className: "dialog"
        }}
        fullScreen
        data-testId = "dialogModalopen"
        open={this.state.flightDetailsDialog}
        scroll= "paper"
        onClose={this.flightDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <Box>
            <Card elevation={4} className="fareOptionContainer">
              <Typography className="fareOPtionTitleText">{configJSON.fareOption}</Typography>
              <img src={cancelIcon} className="cancleButton" onClick={this.flightDialogClose}/>
            </Card>
          </Box>
          <Box className="fareOPtionTitleText travelDuration">
            <span>New Delhi - Mumbai-Bangaluru-New Delhi</span>
              <img src={flightImg} className="flightIcon2"/>
              <span>Indigo</span>
              <span>|16 Apr 24, Tue | Departure at 12:20 PM - Arrival at 2:20 PM </span>
          </Box>
        <DialogContent className="medialContent">
          {this.renderCard()}
        </DialogContent>
        <Divider className="cardHorizentalLine"/>
        <DialogActions className="dialogActionsStyle">
          <Box className="typeCityContainer"> 
          <Typography className="fareOPtionTitleText">$22,200</Typography>
          <Typography className="MultiCityAdult">Multicity for 1 Adult</Typography>
          </Box>
          <Button className="bookButton" onClick={this.flightDialogClose}>
          {configJSON.continue}
          </Button>
        </DialogActions>
      </DialogFlightContainer>
    )
  };

  renderFlightsList = () => {
    return (
      <Box className="container">
        <Box className="topRow">
          <Box>
            <Typography className="multiAirLince">Multiple Airlines</Typography>
            <Typography className="flightName marginTop">
              Indigo, Air India
            </Typography>
          </Box>
          <Box>
            <Typography className="timeDuration">
              Duration : 16 h 50 min
            </Typography>
          </Box>
          <Box>
            <Typography className="flightPrice">$37,860</Typography>
            <Typography className="multiAirLince marginTop">
              Per adult
            </Typography>
          </Box>
          <Button className="priceButton" data-testId = "openDialog" onClick={this.flightDialogOpen}>
            View Prices
          </Button>
        </Box>
        {this.horizontalLine()}
        {this.renderFlightSchedule()}
        {this.horizontalLine()}
        <Box className="flightDetails">
          <Button className="flightDetailsButton" data-testId = "toggleFlightDetails" onClick={this.handleFlightDetails}>
            Flight Details
            <img src={colorOpenIcon} style={{marginLeft: "8px"}}/>
          </Button>
        </Box>
      </Box>
    );
  };

  flightDetails = () => {
    return (
      <Box className="container">
        <Box className="tripBox">
          <Typography className="flightPrice">TRIP 1</Typography>
          <Box className="flightTravelDetails">
            <Typography className="flightListName">Indigo </Typography>
            <Typography className="cityNameText">
              Indore{configJSON.icon} New Delhi{" "}
            </Typography>
          </Box>
          <Box className="flightContainerDetails">
            <Box>
              <Box className="flightLayoverContainer">
                <Box className="flightimageBox">
                  <Box> <img src={flightImg} /></Box>
                  <Typography className="flightNameText marginLeft">
                    Vistara
                  </Typography>
                </Box>
                <Box className="flightScheduleTimeBox">
                  <Box>
                    <Typography className="flightNameText">07:20 PM</Typography>
                    <Typography className="startCity marginTop">Agra</Typography>
                  </Box>
                  <Box className="flightTime">
                    <Typography className="durationTime blackColor">
                      3 h 45 min
                    </Typography>
                    <Divider className="horizental" />
                    <Typography className="durationTime blueColor">
                      2 stop via Raipur
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="flightNameText">12:30 PM</Typography>
                    <Typography className="startCity marginTop">
                      Hyderabad
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="layoverContainer">
                  <Typography className="flightNameText">Change of plane </Typography>
                  <Typography className="startCity marginTop">2 h 30 min Layover in Jaipur</Typography>
              
              </Box>
            </Box>
            <Box>
              <Typography className="baggagesText">
                {configJSON.baggageText} : CHECKIN CABIN
              </Typography>
              <Typography className="adultText">ADULTS 15kgs 7kgs</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderInputTripList = () => {
    return (
      <>
        {this.state.showTrip &&
          this.state.cities.map((city, indexNumber) => (
            <>
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <Typography className="tripText">
                  Trip {indexNumber + 1}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={8}>
                <Grid container spacing={1}>
                  <Grid
                    className="textBoxMargin"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{ position: "relative" }}
                  >
                    <TextField
                      className="catalogue-header-box"
                      name="from"
                      label={
                        <>
                          <span className="catalogue-heading">From</span>
                        </>
                      }
                      variant="outlined"
                      placeholder={"Enter city/ Airport name"}
                      data-testId="openSearchInput"
                      onClick={this.openFromSearchMenu}
                      value={city.from}
                      fullWidth
                      InputProps={{
                        className: "borderCustom",
                        style: {
                          borderRadius: "10px 0 0 10px",
                          textAlign: "center",
                          fontSize: "16px",
                          paddingTop: "10px",
                        },
                      }}
                      InputLabelProps={{
                        className: "fontFrom fontLabel",
                        shrink: true,
                        style: {
                          transform: "translate(14px, 7px)",
                          color: "#000",
                          fontWeight: "700",
                          fontSize: "16px",
                          width: "100%",
                        },
                      }}
                    />
                    <MenuContainer
                      marginThreshold={72}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="menuFrom"
                      id="simple-menu"
                      anchorEl={this.state.fromAnchorEl}
                      PaperProps={{
                        style: {
                          width: 450,
                          borderRadius: "10px",
                        },
                      }}
                      open={Boolean(this.state.fromAnchorEl)}
                      data-testId="closeModal"
                      onClose={this.closeFromSearchMenu}
                      keepMounted
                    >
                      <TextField
                        name="indore"
                        placeholder="From"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          className: "fromSerchInput",
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        style={{ padding: "10px" }}
                      >
                        <Typography className="popularCityText">
                          POPULAR CITIES{" "}
                        </Typography>
                        <MenuItem className="menuItemContainer">
                          <Box className="cityNameListContainer">
                            <Grid container spacing={2}>
                              <Grid item sm={1}>
                                <FlightTakeoffIcon
                                  style={{ color: "rgba(148, 163, 184, 1)" }}
                                />
                              </Grid>
                              <Grid sm={10} item>
                                <Typography className="stateNameTextTo stateColor">
                                  madhya pradesh
                                </Typography>
                                <Typography className="stateNameTextTo cityColor">
                                  Indigo
                                </Typography>
                              </Grid>
                              <Grid item sm={1}>
                                <Typography
                                  className="stateNameTextTo cityColor"
                                  variant="body1"
                                >
                                  bhopal
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </MenuItem>
                      </Box>
                    </MenuContainer>
                    <Box className="reversChangeIcon">
                      <SyncAltIcon
                        style={{
                          color: "rgb(23, 100, 160)",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      className="catalogue-header-box"
                      name="to"
                      label={
                        <>
                          <span className="catalogue-heading">To</span>
                        </>
                      }
                      placeholder={"Enter city/ Airport name"}
                      value={city.to}
                      data-testId="tripInputChange"
                      onClick={this.openToSearchMenu}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        style: {
                          fontSize: "16px",
                          paddingTop: "10px",
                          borderRadius: "0",
                          textAlign: "center",
                        },
                      }}
                      InputLabelProps={{
                        className: "fontFrom fontLabel",
                        shrink: true,
                        style: {
                          overflow: "hidden",
                          height: "100%",
                          transform: "translate(14px, 7px)",
                          color: "#000",
                          fontWeight: "700",
                          fontSize: "16px",
                          width: "100%",
                        },
                      }}
                    />
                    <MenuContainer
                      marginThreshold={72}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="menuFrom"
                      id="simple-menu"
                      anchorEl={this.state.toAnchorEl}
                      PaperProps={{
                        style: {
                          width: 450,
                          borderRadius: "10px",
                        },
                      }}
                      data-testId = "closeSearchMenu"
                      open={Boolean(this.state.toAnchorEl)}
                      onClose={this.closeToSearchMenu}
                      keepMounted
                    >
                      <TextField
                      InputProps={{
                        className: "fromSerchInput",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                        variant="outlined"
                        name="bhopal"
                        placeholder="To"
                        fullWidth
                      />
                      <Box
                        style={{ padding: "10px" }}
                      >
                        <Typography className="popularCityTextTo">
                          POPULAR CITIES{" "}
                        </Typography>
                        <MenuItem className="menuItemContainerTo">
                          <Box className="cityNameListContainerTo">
                            <Grid container spacing={2}>
                              <Grid item sm={1}>
                                <FlightTakeoffIcon
                                  style={{ color: "rgba(148, 163, 184, 1)" }}
                                />
                              </Grid>
                              <Grid sm={10} item>
                                <Typography className="stateNameText stateColor">
                                  maharashtra
                                </Typography>
                                <Typography className="stateNameText cityColor">
                                  air India
                                </Typography>
                              </Grid>
                              <Grid item sm={1}>
                                <Typography className="stateNameText cityColor">
                                  Mumbai
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </MenuItem>
                      </Box>
                    </MenuContainer>
                  </Grid>
                  <Grid
                    className="textBoxMargin"
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                  >
                    <TextField
                      className="catalogue-header-box"
                      name="departureDate"
                      label={
                        <>
                          <span className="catalogue-heading">
                            Departure Date
                          </span>
                        </>
                      }
                      variant="outlined"
                      data-testId="openCalender"
                      onClick={() =>
                        this.togglePickerM("startDate", indexNumber)
                      }
                      value={this.selectTime()}
                      fullWidth
                      InputLabelProps={{
                        className: "fontLabel",
                        shrink: true,
                        style: {
                          transform: "translate(14px, 7px)",
                          color: "White",
                          fontWeight: "700",
                          fontSize: "16px",
                        },
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "0",
                          textAlign: "center",
                          fontSize: "16px",
                          paddingBottom: "10px",
                        },
                      }}
                    />
                  </Grid>
                  {this.state.isOpen && (
                    <Box className="CalenderContainer">
                      <Box className="datepikerTop">
                        <Box className="calTitle">
                          <Typography variant="h4">
                            <span className="font14px">
                              {this.state.startDate
                                ? this.selectDate(this.state.startDate)
                                : "Departure Date"}
                            </span>
                            &nbsp;-&nbsp;
                            <span className="font14px">
                              {this.state.endDate1 ? (
                                this.selectDate(this.state.endDate1)
                              ) : (
                                <>
                                  Select Return Date{" "}
                                  <span className="fontWee400">
                                    (save more on two ways)
                                  </span>
                                </>
                              )}
                            </span>
                          </Typography>
                        </Box>
                        <Box className="calenderBoox">
                          <Calender
                            state={this.state}
                            data-testId = "dataChange"
                            handleDateChange={this.DateChange}
                          />
                        </Box>
                        <Box className="buttonBoxapply">
                          <Button
                            className="closeCalenderButton"
                            data-testId = "calenderCancleClodemodal"
                            onClick={this.handleCancelButton}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Button
                            className="dateApplyButton"
                            onClick={this.handleApplyButton}
                            data-testId = "applyButton"
                          >
                            &nbsp;Apply&nbsp;
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <Box style={{ display: "flex", alignContent: "center" }}>
                  <img
                    src={cancleCity}
                    className="removeImg"
                    data-testId="removeCity"
                    onClick={() => this.handleRemoveCity(indexNumber)}
                  />
                  {indexNumber === this.state.cities.length - 1 && (
                    <>
                      <Button
                        className="addCityButton"
                        data-testId="addCity"
                        onClick={this.handleAddCity}
                      >
                        add City
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </>
          ))}
      </>
    );
  };

  catuagoryButtonList = () => {
    return (
      <Box style={{ padding: "12px" }}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <button
              className={`buttonEconomy ${
                this.state.TravelStatus === "ECONOMY" ? "active" : ""
              }`}
              data-testId = "economyButton"
              onClick={() => this.handleCatuagortClass("ECONOMY")}
            >
              {configJSON.economy}
            </button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <button
              className={`buttonEconomy ${
                this.state.TravelStatus === "PREMIUM" ? "active" : ""
              }`}
              onClick={() => this.handleCatuagortClass("PREMIUM")}
              data-testId = "premiumButton"
            >
              PREMIUM
            </button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <button
              className={`buttonEconomy ${
                this.state.TravelStatus === "Business" ? "active" : ""
              }`}
              data-testId = "businessButton"
              onClick={() => this.handleCatuagortClass("Business")}
            >
              Business
            </button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <button
              className={`buttonEconomy ${
                this.state.TravelStatus === "First Class" ? "active" : ""
              }`}
              data-testId = "firstClassButton"
              onClick={() => this.handleCatuagortClass("First Class")}
            >
              First Class
            </button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  searchFlightHeader = () => {
    return (
      <div className="tripFilterContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <div
              className="tripTypeContainer"
              data-testId="tripTypeOpen"
              onClick={this.handleClick}
            >
              <Box>
              <Box style={{display:"flex",gap:"50px"}}>
                <Typography className="catalogue-heading">
                  {configJSON.tripType}
                </Typography>
                <ExpandMoreIcon />
              </Box>
              <Typography className="selectresponsStyle">{this.state.selectedTripType} </Typography>
              </Box>
            </div>
            <Menu
              marginThreshold={72}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              data-test-id="handleCloseTripType"
              id="simple-menu"
              anchorEl={this.state.anchorElTripType}
              PaperProps={{
                className: "menuTrip",
              }}
              data-testId="tripModalClose"
              open={Boolean(this.state.anchorElTripType)}
              onClose={this.closeTripTypeMenu}
              keepMounted
            >
              <MenuList id="split-button-menu" autoFocusItem>
                <MenuItem
                  className="catalogue-menu selectTravelType"
                  data-testId="selectMultiCity"
                  onClick={() => this.handleSelectTriptype("Multicity")}
                >
                  Multicity
                </MenuItem>
                <MenuItem
                  className="catalogue-menu selectTravelType"
                  data-testId="selectMulRoundtrip"
                  onClick={() => this.handleSelectTriptype("Roundtrip")}
                >
                  Roundtrip
                </MenuItem>
                <MenuItem
                  className="catalogue-menu selectTravelType"
                  data-testId="selectMulOneway"
                  onClick={() => this.handleSelectTriptype("ONE WAY")}
                >
                  ONE WAY 
                </MenuItem>
              </MenuList>
            </Menu>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Box
              className="catalogueHeaderBox"
              data-testId="openMenuOption"
              onClick={this.openTripOption}
            >
              <Box className="fromLableContainer">
                <Typography className="catalogue-heading">From</Typography>
                <ExpandMoreIcon className="expendMoreIcon" />
              </Box>
              <Typography>
                New Delhi To New Delhi, via Mumbai, Bangaluru{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            style={{ overflow: "hidden" }}
          >
            <TextField
              name="travellers & class"
              className="catalogue-header-box"
              label={
                <div className="travellersLableContainer">
                  <div className="travellersLableBox">
                    <span className="catalogue-heading">
                      Travellers & Class
                    </span>
                    <ExpandMoreIcon className="expendMoreIcon" />
                  </div>
                </div>
              }
              placeholder={"Select no.of travellers & Class."}
              variant="outlined"
              fullWidth
              data-testId = "openClassCategory"
              onClick={this.openClassCategory}
              value={this.totalTravellers()}
              data-test-id="handleClickClass"
              style={{ width: "100%" }}
              InputProps={{
                classes: {
                  root: "custom-input-root",
                  input: "catalogue-header-box",
                },
              }}
              InputLabelProps={{
                className: "fontLabel",
                shrink: true,
                style: {
                  transform: "translate(14px, 7px)",
                  color: "#000",
                  fontWeight: "700",
                  fontSize: "16px",
                },
              }}
            />
            <Menu
              marginThreshold={72}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              data-test-id="handleCloseClass"
              className="menuFrom"
              id="simple-menu"
              anchorEl={this.state.anchorElClass}
              PaperProps={{
                style: {
                  width: 600,
                  borderRadius: "10px",
                },
              }}
              data-testId = "closeClassCategory"
              open={Boolean(this.state.anchorElClass)}
              onClose={this.closeClassCategory}
              keepMounted
            >
              <ClassTypeContainer>
                <Box className="classTravelHead">
                  <Typography className="adultText" variant="body1">
                    {this.state.countAdult} Adult , {this.state.countChild}{" "}
                    Children, {this.state.countInfant} Infant
                  </Typography>
                  <Typography className="classStatus" variant="body1">
                    {this.state.TravelStatus}
                  </Typography>
                </Box>
                <Box className="horizontalLine" />
                <Box className="classInputBox">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <Box
                        sx={{
                          padding: {
                            xs: "10px",
                            sm: "10px",
                            md: "19px 10px 0px 10px",
                          },
                          textAlign: "center",
                        }}
                      >
                        <Typography data-testId = "countData" className="categaryText">
                          {this.state.countAdult} Adults
                        </Typography>
                        <Typography className="categaryChange">
                          ({configJSON.ageCategory})
                        </Typography>
                        <Box
                          sx={{
                            padding: {
                              xs: "6px 12px",
                              sm: "6px 16px",
                              md: "6px 20px",
                            },
                          }}
                          className="countDivBox"
                        >
                          <button
                            className="borderNoneCount"
                            data-testId = "countDecrease"
                            onClick={this.countDecrease}
                          >
                            <RemoveIcon />
                          </button>
                          <div className="countShowText">
                            {this.state.countAdult}
                          </div>
                          <button
                            className="borderNoneCount"
                            data-testId = "countIncrease"
                            onClick={this.countIncrease}
                          >
                            <AddIcon />
                          </button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box
                        sx={{
                          textAlign: "center",
                          padding: {
                            xs: "10px",
                            sm: "10px",
                            md: "19px 10px 0px 10px",
                          },
                        }}
                      >
                        <Typography data-testId = "countChildData" 
                        className="categaryText">
                          {this.state.countChild} Children
                        </Typography>
                        <Typography className="yearCategory">
                          ({configJSON.yearCategory})
                        </Typography>
                        <Box
                          sx={{
                            padding: {
                              xs: "6px 12px",
                              sm: "6px 16px",
                              md: "6px 20px",
                            },
                          }}
                          className="countDivBox"
                        >
                          <button
                            className="borderNoneCount"
                            data-testId = "countChildDecrease"
                            onClick={this.countChildDecrease}
                          >
                            <RemoveIcon />
                          </button>
                          <div className="countShowText">
                            {this.state.countChild}
                          </div>
                          <button
                            className="borderNoneCount"
                            data-testId = "countChildIncrease"
                            onClick={this.countChildIncrease}
                          >
                            <AddIcon />
                          </button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          textAlign: "center",
                          padding: {
                            xs: "10px",
                            sm: "10px",
                            md: "19px 10px 0px 10px",
                          },
                        }}
                      >
                        <Typography data-testId = "countChildInfantData" className="categaryText">
                          {this.state.countInfant} Infant
                        </Typography>
                        <Typography className="categaryChange">
                          ({configJSON.underYear})
                        </Typography>
                        <Box
                          sx={{
                            padding: {
                              xs: "6px 12px",
                              sm: "6px 16px",
                              md: "6px 20px",
                            },
                          }}
                          className="countDivBox"
                        >
                          <button
                            className="borderNoneCount"
                            data-testId = "countChildInfantDecrease"
                            onClick={this.countChildInfantDecrease}
                          >
                            <RemoveIcon />
                          </button>
                          <div className="countShowText">
                            {this.state.countInfant}
                          </div>
                          <button
                            className="borderNoneCount"
                            data-testId = "countChildInfantIncrease"
                            onClick={this.countChildInfantIncrease}
                          >
                            <AddIcon />
                          </button>
                        </Box>
                      </Box>
                    </Grid>
                    {this.state.error && (
                      <Grid item sm={12}>
                        <Box className="ErrorMsg errorMsgForCount">
                          <span
                            className="imgError"
                            style={{ width: "5%", marginRight: "5px" }}
                          >
                            <img src={redErrorIcon} />
                          </span>
                          <span style={{ width: "95%" }}>
                            If you want to add more than 9 travellers please
                            contact email{" "}
                            <span style={{ textDecoration: "underline" }}>
                              www.doolwa.com
                            </span>
                          </span>
                        </Box>
                      </Grid>
                    )}
                    <Box className="travelClassBox">
                      <Typography className="travelClassText">
                        {" "}
                        Travel Class
                      </Typography>
                    </Box>
                    <Grid item sm={12}>
                      {this.catuagoryButtonList()}
                    </Grid>
                    <Box className="buttonBoxapply">
                      <Button
                        className="cancleButton"
                        data-testId = "CancelTravelButton"
                        onClick={this.handleCancelTravelButton}
                      >
                        Cancel
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        className="doneButton"
                        data-testId = "DoneButton"
                        onClick={this.handleDoneButton}
                      >
                        &nbsp;Done&nbsp;
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </ClassTypeContainer>
            </Menu>
          </Grid>
          {this.renderInputTripList()}
        </Grid>
      </div>
    );
  };

  renderSearchInput = () => {
    return (
      <Grid
        container
        className="searchFrom searchInput"
        style={{
          position: "relative",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {this.searchFlightHeader()}
        {this.state.showTrip && (
          <Grid item xs={12}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                data-test-id="search-btn"
                className="searchFlight"
              >
                Search Flights
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  renderHeader = () => {
    return (
      <Box sx={webStyle.contentContainer}>
        <div className="SubContentContainer">
          <Button
            className="filter-icon-btn"
            data-testId="filterIcon"
            style={{ display: "none" }}
            onClick={this.handleFiltersToggle}
          >
            <Tune className="tuneStyle" />
          </Button>
          <div className="desktop-filter" data-test-id="filter-block">
            <Filteritems
              inputCityFrom ={""}
              inputCityTo ={""}
              selectedTripType={"oneWayTrip"}
            airlinsFunct={function (): void {
              throw new Error("Function not implemented.");
            }}
            clearAllFunct={function (): void {
              throw new Error("Function not implemented.");
            }}
            airLinesData={[]}
            departureTimePrice={null}
            handleDepartureTimeSelect={function (): void {
              throw new Error("Function not implemented.");
            }}
            handleArrivalTimeSelect={function (): void {
              throw new Error("Function not implemented.");
            }}
            arrivalTimePrice={null}
            timePriceArray={[]}

            shownPopularFilters={[]}
            selectedPopularFilter={[]}
              navigation={this.props.navigation}
              id={""}
              price_range={{ min: 0, max: 0 }}
              selectedPrice={0}
              minPrice={0}
              maxPrice={0}
              nonStopFunct = {function (): void {
                throw new Error("Function not implemented.");
              }}
              onPriceRangeChange={function (): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          <Box sx={webStyle.detailsContainer} className="details-container">
            <Box className="flightList">
              {this.renderFlightsList()}
              {this.state.ShowFlightDetails && this.flightDetails()}
            </Box>
          </Box>
        </div>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MultiCityContainer>
        <Box sx={webStyle.headerContainer}>
          <HeaderMenuBlock navigation={this.props.navigation} id={""} />
          {this.renderSearchInput()}
        </Box>
        {this.renderHeader()}

        <Drawer
          PaperProps={{
            style: {
              height: "90vh",
            },
          }}
          anchor={"bottom"}
          variant="temporary"
          open={this.state.filtersDrawer}
          onClose={() => this.handleFiltersToggle()}
          data-testId="filtersDrawer"
        >
          <div data-test-id="filter-block" />
        </Drawer>
        {this.renderFlightDetailsModal()}
      </MultiCityContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MultiCityContainer = styled(Box)({
  background: "#E9F3FC",
  height: "100%",
  overflowY: "scroll",
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .selectresponsStyle": {
    marginTop: "10px",
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "24px",
    color: "#FFFFFF",
    lineHeight: "18px"
  },
  "& .multiAirLince": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      fontSize: "14px",
    },
  },
  "& .marginTop": {
    marginTop: "16px",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      marginTop: "10px",
    },
  },
  "& .flightName": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "24px",
    color: "#000000",
    "@media screen and (min-width: 601px) and (max-width: 800px)":{
      fontSize: "20px",
    },
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      fontSize: "16px",
    },
    "@media screen and (min-width: 415px) and (max-width: 600px)":{
      fontSize: "18px",
    }
  },
  "& .flightPrice": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      fontSize: "14px",
      marginTop: "10px"
    }
  },
  "& .flightimageBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      justifyContent: "start",
    },
    "@media screen and (min-width: 415px) and (max-width: 640px)":{
      justifyContent: "start",
    },
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      justifyContent: "start",
    }
  },
  "& .timeDuration": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#64748B",
    border: "1px solid #1764A0",
    borderRadius: "100px",
    height: "32px",
    width: "189px",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    paddingTop: "5px",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      marginTop: "10px"
    }
  },
  "& .fliteCard": {
    display: "flex",
    flexDirection: "column" as "column",
    borderRadius: "5px",
    marginBottom: "10px",
    background: "white",
    boxShadow: "0px 4px 10px 0px #00000029",
  },
  "& .subContentContainer": {
    position: "absolute",
    top: "-50px",
    width: "100%",
    display: "flex",
    gap: "21px",
    justifyContent: "space-around",
  },
  "& .tuneStyle": {
    color: "white",
  },
  "& .horizentalLine": {
    background: "#1764A0",
    margin: "40px 0px",
  },
  "& .flightSchuduleContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 0px 25px",
    "@media screen and (min-width: 830px) and (max-width: 1226px)":{
      display: "block",
      padding: "0px 15px"
    },
    "@media screen and (min-width: 360px) and (max-width: 697px)":{
      display: "block",
      padding: "0px 15px"
    }
  },
  "& .tripContainer": {
    display:"flex", 
    gap:"24px", 
    justifyContent:"center",
  },
  "& .flightContainerDetails": {
    display: "flex",
    gap: "25px",
    marginTop: "16px",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      display: "block",
    },
    "@media screen and (min-width: 415px) and (max-width: 640px)":{
      display: "block",
    },
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      display: "block",
    },
    
  },
  "& .flightLayoverContainer": {
    display: "flex",
    gap: "25px",
    width: "100%",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      display: "block",
    },
    "@media screen and (min-width: 415px) and (max-width: 640px)":{
      display: "block",
    },
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      display: "block",
    },
    
  },
  "& .flightNameBox": {
    display: "flex",
    alignItems: "center",
  },
  "& .flightIcon": {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
  },
  "& .flightScheduleTimeBox": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "24px",
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      justifyContent: "space-between",
    },
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
     marginTop: "10px"
    },
  },
  "& .flightTime": {
    alignItems: "center",
    display: "block",
    justifyContent: "center",
  },
  "& .priceButton": {
    background: "#1764A0",
    color: "#FFFFFF",
    width: "148px",
    height: "40px",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "capitalize",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      marginTop: "10px"
    },
  },
  "& .flightList": {
    width: "100%",
  },
  "& .tripName": {
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    "@media screen and (min-width: 360px) and (max-width: 414px)": {
      fontSize: "14px",
    }
  },
  "& .travelTime": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 360px) and (max-width: 414px)": {
      fontSize: "14px",
    }
  },
  "& .flightNameText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 360px) and (max-width: 414px)": {
      fontSize: "14px",
    }
  },
  "& .layoverContainer": {
    borderLeft: "1px dashed #64748B",
    paddingLeft: "20px",
    boxSizing: "border-box",
    alignContent: "center",
    marginTop: "16px"
  },
  "& .marginLeft": {
    marginLeft: "6px",
    boxSizing: "border-box",
  },
  "& .flightNumber": {
    FontFamily: "Inter",
    fontWaight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "16px",
    boxSizing: "border-box",
    color: "#000000"
  },
  "& .startCity": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000000",
  },
  "& .durationTime": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
  },
  "& .blackColor": {
    color: "#000000",
  },
  "& .blueColor": {
    color: "#1764A0",
  },
  "& .horizental": {
    background: "#64748B",
    height: "2px",
    width: "100%",
  },
  "& .flightDetails": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "-24px 30px 32px 0px",
  },
  "& .flightDetailsButton": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1764A0",
    cursor: "pointer",
    textTransform: "capitalize",
    gap: "8px",
  },
  "& .ticketList": {
    background: "#E9F3FC",
    height: "46px",
  },
  "& .listName": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  "& .flightListName": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "white",
    marginLeft: "24px",
    boxSizing: "border-box",
  },
  "& .cityNameText": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "white",
    marginLeft: "4px",
    boxSizing: "border-box",
  },
  "& .flightTravelDetails": {
    background: "#1764A0",
    width: "50%",
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    "@media screen and (min-width: 360px) and (max-width: 414px)": {
      width: "100%",
    }
  },
  "& .saverContainer": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "8px",
  },
  "& .saverText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .fareOfferedText": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .selectButton": {
    width: "120px",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1764A0",
    border: "1px solid #1764A0",
    background: "white",
    borderRadius: "8px",
  },
  "& .flightPrices": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .baggagesText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    "@media screen and (min-width: 360px) and (max-width: 600px)": {
      marginTop: "10px",
    }
  },
  "& .adultText": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#64748B",
  },
  "& .searchFlight": {
    backgroundColor: "white",
    color: "rgb(23, 100, 160)",
    width: "327px",
    height: "56px",
    fontFamily: "Inter, sans-serif",
    fontWeight: "700",
    fontSize: "16px",
    textTransform: "none",
  },
  "& .tripTypeContainer": {
    display: "flex",
    width: "100%",
    cursor: "pointer",
    height: "100%",
    backgroundColor: "#1764A080",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWight: 400,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },
  "& .tripFilterContainer": {
    width: "100%",
    padding: "35px 23px 0px 23px",
  },
  "& .travellersLableContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  "& .travellersLableBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .expendMoreIcon": {
    color: "white",
  },
  "& .fromLableContainer": {
    display: "flex",
    alignItems: "center",
    gap: "80px",
  },
  "& .catalogueHeaderBox": {
    backgroundColor: "#1764A080",
    borderRadius: "8px",
    fontSize: "24px !important",
    fontFamily: "inherit",
    fontWeight: 400,
    color: "white",
    padding: "26px 16px",
  },
  "& .tripText": {
    fontSize: "24px",
    fontWeight: 700,
    color: "white",
    fontFamily: "inherit",
    marginTop: "10px",
    textAlign: "end",
  },
  "& .addCityButton": {
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    fontWeight: 700,
    color: "white",
    fontFamily: "inherit",
    borderRadius: "8px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  "& .calenderContainer": {
    borderRadius: "10px",
    width: "auto",
    top: "22%",
    left: "50%",
    transform: "translate(-50%, 0)",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
    position: "absolute",
    zIndex: 9999,
    background: "#fff",
  },
  "& .closeCalenderButton": {
    color: "rgba(23, 100, 160, 1)",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .dateApplyButton": {
    background: "rgba(23, 100, 160, 1)",
    color: "#fff",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .removeImg": {
    cursor: "pointer",
  },
  "& .errorMsgForCount": {
    margin: "0px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    borderLeft: "6px #c10f0ff0 solid",
    background: " #ed7e7e3d",
    fontFamily: "Inter, sans-serif",
    color: "#c12121",
    display: "flex",
    lineHeight: "24px",
    padding: "20px 15px",
    alignItems: "center",
  },
  "& .container": {
    display: "flex",
    flexDirection: "column" as "column",
    borderRadius: "5px",
    marginBottom: "10px",
    background: "white",
    boxShadow: "0px 4px 10px 0px #00000029",
    "@media screen and (min-width: 830px) and (max-width: 414px)": {

    }
  },
  "& .tripBox": {
    padding: "20px"
  },
  "& .topRow": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "25px 25px 0px 25px",
    "@media screen and (min-width: 830px) and (max-width: 1226px)": {
      padding: "15px 15px 0px 15px",
    },
    "@media screen and (min-width: 415px) and (max-width: 600px)": {
      padding: "25px 25px 0px 25px",
      display: "block",
    },
    "@media screen and (min-width: 601px) and (max-width: 700px)": {
      padding: "15px 15px 0px 15px",
    },
    "@media screen and (min-width: 360px) and (max-width: 414px)": {
      padding: "15px 15px 0px 15px",
      display: "block",
    }
  },
});

const DialogFlightContainer = styled(Dialog)({
  "& .dialog": {
    width: "100%",
    height: "95%",
    borderRadius: "8px",
    background: "#FFFFFF"
  },
  "& .fareOptionContainer": {
    background: "#FFFFFF",
    borderBottom:"",
    width: "100%",
    height: "77px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 22px 0px 55px",
    boxSizing:"border-box",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      padding: "0px 15px 0px 15px",
    }
  },
  "& .fareOPtionTitleText": {
    fontFamily:"Inter", 
    fontSize:"24px", 
    fontWeight: 700, 
    lineHeight:"24px",
    color:"#000000",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      fontSize:"14px",
    },
    "@media screen and (min-width: 415px) and (max-width: 600px)":{
      fontSize:"16px",
    },
    "@media screen and (min-width: 601px) and (max-width: 950px)":{
      fontSize:"20px",
    }
  }, 
  "& .travelDuration": {
    padding: "24px",
    textJustify:"auto",
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
      padding: "15px",
    }
  },
  "& .cancleButton": {
    curcer: "pointer"
  },
  "& .MuiDialogContent-root": {
    padding: "0px"
  },
  "& .pricePersionContainer": {
    display: "flex",
  },
  "& .cardContainer": {
    width: "446px",
    height: "590px",
    borderRadius: "8px",
  },
  "& .MuiCardContent-root": {
    padding:"0px"
  },
  "& .medialContent": {
    height: 'calc(100vh - 64px - 64px)',
    overflowY: 'scroll',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#1764A0', 
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#1e88e5',
    },
    scrollbarColor: '#1764A0 #f1f1f1',
    scrollbarWidth: 'auto',
  },
  "& .flightIcon2": {
    borderRadius: "50%",
    height: "24px",
    width: "24px",
    margin:"0px 6px"
  },
  "& .cardHorizentalLine": {
    background: "#94A3B8",
  },
  "& .cardTitleBold": {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#000000",
  },
  "& .categoryPrice": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
  },
  "& .persionType": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    color: "#64748B",
    marginLeft:"8px"
  },
  "& .MultiCityAdult": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    marginTop: "8px",
  },
  "& .categortType": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    color: "#64748B",
    marginTop: "10px"
  },
  "& .baggageQuantity": {
    fontFamily: "Inter",
    fontSize: " 14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#000000",
    marginLeft: "16px",
    boxSizing: "border-box"
  },
  "& .quantityContainer": {
    display: "flex",
    alignItems: "center",
    margin: "16px 0px"
  },
  "& .bookButton": {
    width: "120px",
    height: "56px",
    borderRadius: "8px",
    background: "#1764A0",
    color: "#FFFFFF",
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: 600,
    cursor:"pointer",
    textTransform: "capitalize"
  },
  "& .BookNowContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .baggageContainer": {
    padding: "24px"
  },
  "& .dialogActionsStyle": {
    padding:"17px 24px 43px 24px",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      padding:"17px 15px 43px 15px",
    }
  },
  "& .typeCityContainer": {
    marginRight:"43px", 
    boxSizing:"border-box",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      marginRight:"77px", 
    }
  },
  "& .cardContainerBox":{
    padding: "24px", 
    boxSizing: "border-box", 
    gap:"8px",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      padding: "15px", 
    }
  },
  "& .cardContentContainer": {
    padding: "24px",
    boxSizing: "border-box",
    display: "flex",
    gap: "8px",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      padding: "15px", 
    }
  }
});

const ClassTypeContainer = styled(Box)({
  padding: "10px",
  "& .classStatus": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
  },
  "& .adultText": {
    fontSize: "12px",
    color: "#000",
    fontWeight: 700,
  },
  "& .horizontalLine": {
    width: "100%",
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
    marginTop: "10px",
  },
  "& .classInputBox": {
    width: "100%",
  },
  "& .categaryText": {
    fontSize: "24px",
    color: "#000",
    fontWeight: "700",
  },
  "& .categaryChange": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
    padding: "10px 0 20px 0",
  },
  "& .yearCategory": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
    padding: "10px 0 20px 0",
  },
  "& .travelClassBox": {
    width: "100%",
    textAlign: "center",
    padding: "0px 0px 0px 0px",
  },
  "& .travelClassText": {
    fontSize: "24px",
    color: "#000",
    fontWeight: "700",
  },
  "& .buttonBoxapply": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    margin: "12px 10px",
    padding: "10px",
  },
  "& .cancleButton": {
    color: "rgba(23, 100, 160, 1)",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
    cursor: "pointer"
  },
  "& .doneButton": {
    background: "rgba(23, 100, 160, 1)",
    color: "#fff",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .selectTravelType": {
    fontFamily: "inter",
    color: "#000000",
    cursor: "pointer"
  },
  
});

const MenuContainer = styled(Menu)({
  "& .fromSerchInput": {
    textAlign: "center",
    fontSize: "16px",
    boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
    border: "none",
  },
  "& .popularCityText": {
    fontSize: "16px",
    color: "rgba(100, 116, 139, 1)",
    padding: "20px",
    fontWeight: "600",
  },
  "& .popularCityTextTo": {
    color: "rgba(100, 116, 139, 1)",
    fontWeight: "600",
    padding: "20px",
    fontSize: "16px",
  },
  "& .menuItemContainer": {
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
  },
  "& .cityNameListContainer": {
    paddingTop: "10px",
    width: "100%",
  },
  "& .cityNameListContainerTo": {
    width: "100%",
    paddingTop: "10px",
  },
  "& .menuItemContainerTo": {
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
  },
  "& .stateNameText": {
    fontWeight: "600",
    fontSize: "12px",
    paddingBottom: "12px",
  },
  "& .stateNameTextTo": {
    paddingBottom: "12px",
    fontSize: "12px",
    fontWeight: "600",
  },
  "& .stateColor": {
    color: "#00000",
  },
  "& .cityColor": {
    color: "rgba(100, 116, 139, 1)",
  },
  "& .flightIconColor": {
    color: "rgba(148, 163, 184, 1)",
  },
});

const flightItemStyle = {
  durationColumn: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24",
    paddingTop: "14",
    paddingBottom: "14",
    paddingRight: "25",
  },
  flightHeading: {
    fontFamily: "Inter, sans-serif",
    fontWeight: "700",
    fontSize: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  flightDetails: {
    display: "flex",
    alignItems: "center",
  },
  flightLogo: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  priceDetails: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  placeText: {
    fontFamily: "Inter, sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    color: "#64748B",
  },
};

const webStyle = {
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    flexWrap: "wrap",
    width: "60%",
    height: "100%",
    background: "transparent",
    borderRadius: "8px",
    marginRight: "21px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    height: "100%",
    background: "#E9F3FC",
    gap: "21px",
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    paddingBottom: "100px",
  },
};

// Customizable Area End
